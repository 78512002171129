import crypto from "crypto";
import { PROCESSING_FEE_TYPES } from "../constants";
import config from "../constants/config";

export const formatCurrency = (currency) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "AED",
  }).format(currency);

export const getPhoneString = (phone) =>
  [phone?.countryCode, phone?.areaCode, phone?.number]
    .filter(Boolean)
    .join(" ");

/* cSpell:disable */
export const getShippingFee = (from = "default", to = "default") => {
  const feeStructure = {
    dubai: {
      dubai: 15,
      sharjah: 15,
      ajman: 20,
      "abu dhabi": 20,
      default: 25,
    },
    "abu dhabi": {
      "abu dhabi": 15,
      default: 25,
    },
    sharjah: {
      dubai: 15,
      sharjah: 15,
      ajman: 15,
      default: 25,
    },
    ajman: {
      dubai: 20,
      sharjah: 15,
      ajman: 15,
      default: 25,
    },
    default: {
      default: 25,
    },
  };

  const _from = feeStructure[from.toLowerCase()] || feeStructure["default"];
  return _from[to.toLowerCase()] || _from["default"];
};

export const getErfaPrice = (
  supplierPrice,
  platformMarginType,
  platformMargin
) => {
  return (
    supplierPrice +
    (platformMarginType === "Percentage"
      ? (supplierPrice * platformMargin) / 100
      : platformMargin)
  );
};

export const getDiscountedPrice = (
  supplierPrice,
  platformMarginType,
  platformMargin,
  discountType,
  discount
) => {
  const erfaPrice = getErfaPrice(
    supplierPrice,
    platformMarginType,
    platformMargin
  );
  return (
    erfaPrice -
    (discountType === "Percentage" ? (erfaPrice * discount) / 100 : discount)
  );
};

export const getCouponDiscountedPrice = (price, coupons = null) => {
  if (coupons) {
    let coupontDiscount =
      coupons.discountType === "Percentage"
        ? (price * coupons.discount) / 100
        : coupons.discount;
    if (coupontDiscount >= price) {
      coupontDiscount = price;
    }
    return coupontDiscount;
  }
  return 0;
};

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const capitalizeFirstLetter = (val = "") => {
  if (val.length === 0) return val;
  return val.charAt(0).toUpperCase() + val.slice(1);
};

export const propertyStatusString = (propertyStatus, leaseStatus='') => {
  let text='';
  if(propertyStatus!=="approved"){
    text+= propertyStatus==='approval_pending' ? `Property approval pending` : 'Property is Blocked';
  } else {
    if(leaseStatus!=="approved"){
      text+= leaseStatus==='approval_pending' ? `Lease approval pending` : 'Lease is Blocked';
    } else {
      text+= 'Approved'
    }
  }
  return text;
}

export const formatOwnerContact = (value) => {
  let formattedValue = value.replace(/[^\d]/g, ""); // Remove non-digits
  const countryCode = "+971";

  if (formattedValue.startsWith(countryCode)) {
    formattedValue = formattedValue.slice(0, 7) + " " + formattedValue.slice(7); // Add space after the area code
  } else if (value.length >= 1) {
    formattedValue = countryCode + formattedValue.slice(3); // Ensure the number always starts with +971
  }

  // Insert spaces after the country and area codes
  formattedValue = formattedValue.replace(/^(\+971)(\d{2})(.*)/, `$1 $2 $3`);
  formattedValue = formattedValue.replace(
    /^(\+971 \d{2})(\d{3})(.*)/,
    `$1 $2$3`
  );

  // Insert a hyphen in the last part of the number
  formattedValue = formattedValue.replace(
    /^(\+971 \d{2} \d{3})(.*)/,
    (match, p1, p2) => {
      const trimmedPart = p2.slice(0, 4); // Trim more than 4 characters after the hyphen
      return `${p1}-${trimmedPart}`;
    }
  );

  return formattedValue;
};

export const downloadPDFBlob = async (blob) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  // const base64 = await blobToBase64(blob)
  var newBlob = new Blob([blob], { type: "application/pdf" });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  link.download = "file.pdf";
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const isValidateDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) return true;
  if (new Date(startDate) > new Date(endDate)) {
    return false;
  }
  return true;
};

export const getFileExtension = (filename) => {
  return filename?.split(".")?.pop();
};

export const formattedDate = (d) => {
  //dd/mm/yyyy
  let fDate = new Date(d);
  return `${String(
    fDate.getUTCDate()
  ).padStart(2, "0")}/${String(fDate.getUTCMonth() + 1).padStart(2, "0")}/${fDate.getUTCFullYear()}`;
};

export const getZeroedISODate = (d) => {
  let s = new Date(d);
  s.setUTCHours(0, 0, 0, 0);
  return s.toISOString();
};

export const calculateInvoiceDue = (invoiceAmount, paymentArray = []) => {
  if (paymentArray.length === 0) return invoiceAmount;
  const totalPayment = paymentArray
    .map((s) => s.originalAmount || s.amount)
    .reduce((a, b) => a + b);

  return (invoiceAmount - totalPayment).toString();
};

export const roundValue = (value, decimals = 2) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

export const arrayToCommaString = (array) => {
  const filteredArray = array.filter(
    (value) => value !== null && value !== undefined && value !== ""
  );
  return filteredArray.join(", ");
};

export const getProcessingFee = (settings) => {
  return Object.keys(settings || {}).length > 0
    ? ` ${settings?.processingFees}${
        settings?.processingFeesType === PROCESSING_FEE_TYPES[0] ? " AED" : "%"
      } `
    : " 0";
};

export const getPaymentUrl = ({ invoiceId, amount, extra, data }) => {
  // return `${config.API_URL}/gateway/payfort/initialize?invoiceId=${invoiceId}&amount=${amount}&extra=${extra}`;

  return `${config.API_URL}/gateway/payfort/initialize?method=enc&q=${data}`;
};

// Payfort objectEncryt
export const stringifyPayfortObject = (obj, seperators = ["--", ";;"]) => {
  let keys = Object.keys(obj);
  let finalString = "";
  let stringArr = [];
  keys.forEach((key) => {
    stringArr.push(`${[key, obj[key]].join(seperators[0])}`);
  });
  finalString = stringArr.join(seperators[1]);
  return finalString;
};

export const objectifyPayfortObject = (string, seperators = ["--", ";;"]) => {
  let keyValueArrays = string.split(seperators[1]);
  let object = {};
  keyValueArrays.forEach((arr) => {
    let item = arr.split(seperators[0]);
    object[item[0]] = item[1];
  });
  return object;
};

export const encryptData = (text) => {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(
    "aes-256-cbc",
    Buffer.from(config.ENCRYPTION_KEY),
    iv
  );
  let encrypted = cipher.update(JSON.stringify(text));
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return `${iv.toString("hex")}:${encrypted.toString("hex")}`;
};

export const decryptData = (text) => {
  const textParts = text.split(":");
  const iv = Buffer.from(textParts.shift(), "hex");
  const encryptedText = Buffer.from(textParts.join(":"), "hex");
  const decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    Buffer.from(config.ENCRYPTION_KEY),
    iv
  );
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return JSON.parse(decrypted.toString());
};

export const amountToCommadString = (amount) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const availablePaymentTypesString = (propertyAdminDetails) => {
  let paymentTypes = [];
  if (propertyAdminDetails?.credit_card_enabled) {
    paymentTypes.push("Credit Card");
  }
  if (propertyAdminDetails?.tabby_enabled) {
    paymentTypes.push("Tabby");
  }
  if (propertyAdminDetails?.lean_enabled) {
    paymentTypes.push("Instant Transfer");
  }
  return paymentTypes.join(", ");
}

// Mask email addresses
export const maskEmail = (email) => {
  if (!email) return '';

  const [username, domain] = email.split('@');
  const usernameLength = username.length;

  if (usernameLength <= 2) {
    // Very short usernames like "ab" → keep first and last, no masking
    return `${username}@${domain}`;
  }

  if (usernameLength <= 6) {
    // Small usernames like "abcde" → show first and last, mask middle
    return `${username.charAt(0)}${'*'.repeat(usernameLength - 2)}${username.charAt(usernameLength - 1)}@${domain}`;
  }

  if (usernameLength <= 10) {
    // Medium usernames like "abcdefghi" → show first 2, last 2
    const start = username.slice(0, 2);
    const end = username.slice(-2);
    const masked = '*'.repeat(usernameLength - 4);
    return `${start}${masked}${end}@${domain}`;
  }

  // Long usernames → show first 3, last 3
  const start = username.slice(0, 3);
  const end = username.slice(-3);
  const masked = '*'.repeat(usernameLength - 6);
  return `${start}${masked}${end}@${domain}`;
};
