import Axios from "axios";
import config from "../constants/config";

Axios.defaults.baseURL = config.API_URL;
// Axios.defaults.baseURL = "http://localhost:3033";

export const uploadFile = (file, id) => {
  console.log("my file id is", id);
  var formData = new FormData();
  formData.append("asset", file);
  return Axios.post(`/admin/assets/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `${
        JSON.parse(localStorage.getItem("user") || "{}").token
      }`,
    },
  }).then((response) => response.data);
};

export const uploadRentAgreement = (file, id) => {
  var formData = new FormData();
  formData.append("asset", file);
  return Axios.post(`/admin/assets/property/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `${
        JSON.parse(localStorage.getItem("user") || "{}").token
      }`,
    },
  }).then((response) => response.data);
};

const request = async ({ method = "get", url, data, params, extra }) => {
  try {
    const config = { url, method, ...extra };
    const token = localStorage.getItem("token");
    if (token) config.headers = { Authorization: `${token}` };
    if (!["GET", "get"].includes(method) && data) config.data = data;
    if (params) config.params = params;
    const resp = await Axios({ ...config });
    return resp.data;
  } catch (e) {
    throw e.response.data;
  }
};

export const login = async ({ email, password }) => {
  return request({
    method: "post",
    url: "/admin/auth",
    data: { email, password },
  });
};

export const register = async ({ name, email, password }) => {
  return request({
    method: "post",
    url: "/admin/register",
    data: { name, email, password },
  });
};

export const getUsers = async (
  status = null,
  sDate = null,
  eDate = null,
  name = null,
  number = null
) => {
  // let s = status ? status : ''
  let startDate = sDate ? sDate : "";
  let endDate = eDate ? eDate : "";
  let search = name ? name : "";
  let searchNumber = number ? number : "";
  return request({
    url: `/admin/tenants?isVerified=${status}&startDate=${startDate}&endDate=${endDate}&search=${search}&number=${searchNumber}`,
  });
};

export const fetchTenants = async ({ name = null, isActive = null }) => {
  // let s = status ? status : ''
  let search = name ? name : "";
  return request({
    url: `/admin/tenats?search=${search}&isActive=${isActive}`,
  });
};

export const getAdminCustomerId = async (id) => {
  return request({
    url: `/admin/admin-user/leantech-customer-admin/${id}`
  });
};
export const getLeanBanks = async () => {
  return request({
    url: `/gateway/leantech/bank`
  });
};
export const getPropertyAdminLeanBankDetails = async (propertyAdminId) => {
  return request({
    url: `/admin/property-admin/${propertyAdminId}/leantech/bank-account`
  });
};
export const getPaymentDestination = async (invoiceId) => {
  return request({
    url: `/invoice/${invoiceId}/leantech/payment-destination`
  });
};
export const getCustomerIdByUserId = async ({userId}) => {
  return request({
    url: `/admin/admin-user/leantech-customer/${userId}`
  });
};
export const getUserPaymentSourcesByUserId = async ({userId}) => {
  return request({
    url: `/user/${userId}/leantech/payment-source`
  });
};
export const disconnectUserLeanBankAccount = async ({userId}) => {
  return request({
    method: "delete",
    url: `/user/${userId}/leantech/disconnect`
  });
};
export const savePropertyAdminLeanBankDetails = async (propertyAdminId, bankAccountDetails) => {
  return request({
    method: "post",
    url: `/admin/property-admin/${propertyAdminId}/leantech/bank-account`,
    data: bankAccountDetails,
  });
};


export const getInitialOwners = async (value) => {
  // let s = status ? status : ''
  return request({
    url: `/admin/owner?search=${value}`,
  });
};

export const getOwnerLeaseUnits = async (value) => {
  // let s = status ? status : ''
  return request({
    url: `/admin/owner/leaseUnit?search=${value}`,
  });
};

export const __paymentDetails = (id) => {
  return request({ url: `/admin/payment-details/${id}` });
};

export const getList = async (list) => {
  return request({
    url: `/admin/get-lists?lists=${JSON.stringify(list)}`,
  });
};

export const getTanent = async (id) => {
  return request({
    url: `/admin/${id}/tenant`,
  });
};
export const getAllTenantsByPropertyCode = async (id)=>{
  return request({
    url: `/admin/tenants/${id}`,
  });
}

export const getProperties = async (userId) => {
  return request({
    url: `/admin/properties/${userId}`,
  });
};

export const getLeaseByUserId = async (userId) => {
  return request({
    url: `/admin/leaseByUserId/${userId}`,
  });
};

export const getLeaseByPropertyId = async (propertyId) => {
  return request({
    url: `/admin/getLeaseByPropertyId/${propertyId}`,
  });
};

export const getProperty = async (id) => {
  return request({
    url: `/admin/property/${id}`,
  });
};

export const updatePropertyDetails = async (id, data) => {
  return request({
    method: "put",
    url: `/admin/property/${id}`,
    data,
  });
};

export const updatePropertyHumanFriendlyId = async (id, data) => {
  return request({
    method: "put",
    url: `/admin/property/humanFriendlyId/${id}`,
    data,
  });
}

export const userVerify = async (id, value) => {
  return request({
    method: "put",
    url: "/admin/user-verification",
    data: { id, verificationStatus: value },
  });
};

export const createNotes = async (data) => {
  return request({
    method: "post",
    url: "/admin/add-notes",
    data,
  });
};

export const notesUpdate = async (data) => {
  return request({
    method: "put",
    url: "/admin/update-notes",
    data,
  });
};

export const addTenant = async (values) => {
  return request({
    method: "post",
    url: `/admin/tenant`,
    data: values,
  });
};

export const tenantUpdate = async (id, values, userId) => {
  return request({
    method: "put",
    url: `/admin/tenant/${id}`,
    data: { data: { ...values, userId } },
  });
};

export const tenancyContractUpdate = async (id, values, userId) => {
  return request({
    method: "put",
    url: `/admin/tenancycontract/${id}`,
    data: { data: { ...values, userId } },
  });
};

export const userUpdate = async (id, values) => {
  return request({
    method: "put",
    url: `/admin/user/${id}`,
    data: values,
  });
};

export const createLeaseApi = async (id, values) => {
  return request({
    method: "post",
    url: `/admin/property/${id}/leased-unit`,
    data: { data: values },
  });
};

export const updateLeased = async (id, values) => {
  return request({
    method: "put",
    url: `/admin/leased-unit/${id}`,
    data: { data: values },
  });
};

export const updateStatus = async (data) => {
  return request({
    method: "put",
    url: `/admin/status`,
    data,
  });
};

export const updateLeaseStatus = async (data) => {
  return request({
    method: "put",
    url: `/admin/lease/status`,
    data,
  });
};

export const createInvoiceApi = async (leaseId, values) => {
  return request({
    method: "post",
    url: `/admin/add-invoice/${leaseId}`,
    data: { data: values },
  });
};

export const updateInvoiceApi = async (invoiceId, values) => {
  return request({
    method: "put",
    url: `/admin/invoice/${invoiceId}`,
    data: { data: values },
  });
};

export const updatePaymentStatusApi = async (paymentId, values) => {
  return request({
    method: "put",
    url: `/admin/payment-status/${paymentId}`,
    data: { data: values },
  });
};

export const allProperties = async (name, filterStatus, startDate, endDate) => {
  let search = name ? name : "";
  let status = filterStatus ? filterStatus : "";
  let sDate = startDate ? startDate : "";
  let eDate = endDate ? endDate : "";
  return request({
    method: "get",
    url: `/admin/properties?search=${search}&status=${status}&startDate=${sDate}&endDate=${eDate}`,
  });
};

export const getLeaseByPropertyCode = async (propertyCode) => {
  return request({
    method: "get",
    url: `/admin/property/leases/${propertyCode}`,
  });
};

export const createManualPayment = (data) => {
  return request({
    method: "post",
    url: "/admin/manual-payment",
    data: data,
  });
};

export const tenantsProperty = async (
  name,
  filterStatus,
  startDate,
  endDate
) => {
  let search = name ? name : "";
  let status = filterStatus ? filterStatus : "";
  let sDate = startDate ? startDate : "";
  let eDate = endDate ? endDate : "";
  return request({
    method: "get",
    url: `/admin/get-payments-list?search=${search}&status=${status}&startDate=${sDate}&endDate=${eDate}`,
  });
};

export const resetPasswordRequest = ({ email }) => {
  return request({
    method: "post",
    url: "/admin/reset-password-request",
    data: { email },
  });
};

export const resetPassword = ({
  email,
  resetPasswordRequestId,
  password,
  isSuperAdmin,
}) => {
  return request({
    method: "post",
    url: "/admin/reset-password",
    data: { email, resetPasswordRequestId, password, isSuperAdmin },
  });
};

export const checkLogin = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return request({ url: "/superusers/" + user._id });
};

export const createSupplier = (data) => {
  return request({ method: "post", url: "/suppliers", data });
};

export const updateSupplier = (_id, data) => {
  return request({ method: "patch", url: "/suppliers/" + _id, data });
};

export const updateReseller = (_id, data) => {
  const { phone, ...payload } = data;
  return request({ method: "patch", url: "/resellers/" + _id, data: payload });
};

export const createCategory = (data) => {
  return request({ method: "post", url: "/categories", data });
};

export const updateCategory = (_id, data) => {
  return request({ method: "patch", url: "/categories/" + _id, data });
};

export const createSubcategory = (data) => {
  return request({ method: "post", url: "/subcategories", data });
};

export const updateSubcategory = (_id, data) => {
  return request({ method: "patch", url: "/subcategories/" + _id, data });
};

export const createSubSubcategory = (data) => {
  return request({ method: "post", url: "/sub-subcategories", data });
};

export const updateSubSubcategory = (_id, data) => {
  return request({ method: "patch", url: "/sub-subcategories/" + _id, data });
};

export const createCollection = (data) => {
  return request({ method: "post", url: "/collections", data });
};

export const updateCollection = (_id, data) => {
  return request({ method: "patch", url: "/collections/" + _id, data });
};

export const createProduct = (data) => {
  return request({ method: "post", url: "/products", data });
};

export const updateProduct = (_id, data) => {
  return request({ method: "patch", url: "/products/" + _id, data });
};

export const createAdvertisement = (data) => {
  return request({ method: "post", url: "/advertisements", data });
};

export const updateAdvertisement = (_id, data) => {
  return request({ method: "patch", url: "/advertisements/" + _id, data });
};

export const deleteAdvertisement = (_id) => {
  return request({ method: "delete", url: "/advertisements/" + _id });
};

export const deleteCoupon = (_id) => {
  return request({ method: "delete", url: "/coupons/" + _id });
};

export const createAdvertisementBanner = (data) => {
  return request({ method: "post", url: "/advertisement-banners", data });
};

export const createBanner = (data) => {
  return request({ method: "post", url: "/admin/banners", data });
};

export const updateBanner = (_id, data) => {
  return request({ method: "patch", url: "/admin/banners/" + _id, data });
};

export const deleteBanner = (_id) => {
  return request({ method: "delete", url: "/admin/banners/" + _id });
};

export const updateAdvertisementBanner = (_id, data) => {
  return request({
    method: "patch",
    url: "/advertisement-banners/" + _id,
    data,
  });
};

export const deleteAdvertisementBanner = (_id) => {
  return request({ method: "delete", url: "/advertisement-banners/" + _id });
};

export const deleteReferral = (_id) => {
  return request({ method: "delete", url: "/referral-network/" + _id });
};

export const addReferralNetwork = (_id, data) => {
  return request({ method: "patch", url: "/referral-network/" + _id, data });
};

export const createOrder = (data) => {
  return request({ method: "post", url: "/orders", data });
};
export const updateOrder = (_id, data) => {
  return request({ method: "patch", url: "/orders/" + _id, data });
};

export const updatePayment = (_id, data) => {
  return request({ method: "patch", url: "/payments/" + _id, data });
};

export const createSuperuser = (data) => {
  return request({ method: "post", url: "/admin/superusers", data });
};

export const updateSuperuser = (_id, data) => {
  return request({ method: "put", url: "/admin/superusers/" + _id, data });
};

export const getSettings = () => {
  return request({ url: `/admin/settings` });
};
export const updateSettings = (data) => {
  return request({ method: "post", url: "/admin/settings/", data });
};

export const _updateSettings = (data) => {
  return request({ method: "patch", url: "/cms/settings", data });
};

export const updateResellerPayment = (ids, values) => {
  return request({
    method: "patch",
    url: "/payments/reseller",
    data: { ids, ...values },
  });
};

export const updateSupplierPayment = (ids, values) => {
  return request({
    method: "patch",
    url: "/payments/supplier",
    data: { ids, ...values },
  });
};

export const createResellerBank = (resellerId, values) =>
  request({
    method: "post",
    url: `/resellers/bank/${resellerId}`,
    data: { ...values },
  });

export const updateResellerBank = (resellerId, bankId, values) =>
  request({
    method: "patch",
    url: `/resellers/bank/${resellerId}/${bankId}`,
    data: { ...values },
  });

export const createSupplierBank = (supplierId, values) =>
  request({
    method: "post",
    url: `/suppliers/bank/${supplierId}`,
    data: { ...values },
  });

export const updateSupplierBank = (supplierId, bankId, values) =>
  request({
    method: "patch",
    url: `/suppliers/bank/${supplierId}/${bankId}`,
    data: { ...values },
  });

export const updateBonus = (data) => {
  return request({ method: "post", url: "/bonus-settings/", data });
};

export const createCoupon = (data) => {
  return request({ method: "post", url: "/coupons", data });
};
export const updateCoupon = (_id, data) => {
  return request({ method: "patch", url: "/coupons/" + _id, data });
};

export const get = {
  allSuppliers: ({ params }) => {
    return request({ url: "/allSuppliers", params });
  },
  suppliers: ({ params }) => {
    return request({ url: "/suppliers", params });
  },
  supplier: (_id) => {
    return request({ url: "/suppliers/" + _id });
  },
  resellers: ({ params }) => {
    return request({ url: "/resellers", params });
  },
  reseller: (_id) => {
    return request({ url: "/resellers/" + _id });
  },
  referralNetwork: ({ params }) => {
    return request({ url: "/referral-network/" + params.id, params });
  },
  categories: (params = {}) => {
    return request({ url: "/categories", params });
  },
  subcategories: (params = {}) => {
    return request({ url: "/subcategories", params });
  },
  subSubcategories: (params = {}) => {
    return request({ url: "/sub-subcategories", params });
  },
  allCollections: ({ params }) => {
    return request({ url: "/allCollections", params });
  },
  allCategories: ({ params }) => {
    return request({ url: "/allCategories", params });
  },
  allSubcategories: ({ params }) => {
    return request({ url: "/allSubcategories", params });
  },
  collections: ({ params }) => {
    return request({ url: "/collections", params });
  },
  collection: (_id) => {
    return request({ url: "/collections/" + _id });
  },
  allProducts: ({ params }) => {
    return request({ url: "/allProducts", params });
  },
  products: ({ params }, extra) => {
    return request({ url: "/products", params, extra });
  },
  product: (_id) => {
    return request({ url: "/products/" + _id });
  },
  advertisements: ({ params }) => {
    return request({ url: "/advertisements", params });
  },
  advertisementBanners: ({ params }) => {
    return request({ url: "/advertisement-banners", params });
  },
  orders: ({ params }) => {
    return request({ url: "/orders", params });
  },
  orderReport: ({ params }) => {
    return request({ url: "/reports/order", params });
  },
  order: (_id) => {
    return request({ url: "/admin/property/" + _id });
  },
  superusers: ({ params }) => {
    return request({ url: "/admin/superusers", params });
  },
  superuser: (_id) => {
    return request({ url: "/admin/superusers/" + _id });
  },
  settings: () => {
    return request({ url: "/cms/settings" });
  },
  supplierProfile: (_id) => {
    return request({ url: "/website/pages/supplier?supplierId=" + _id });
  },
  resellerPayment: ({ params }) =>
    request({ url: "/payments/reseller", params }),
  supplierPayment: ({ params }) =>
    request({ url: "/payments/supplier", params }),
  bonus: (_id) => {
    return request({ url: "/bonus/" + _id });
  },
  bonuses: ({ params }) => request({ url: "/bonus-details", params }),

  bonusSettings: ({ params }) => request({ url: "/bonus-settings", params }),

  coupon: (_id) => {
    return request({ url: "/coupons/" + _id });
  },
  coupons: ({ params }) => request({ url: "/coupons", params }),

  resellerPaymentDetail: (_id, status, transactionDate) =>
    request({
      url: `/payments/reseller/${_id}/${status}${
        transactionDate ? `/${transactionDate}` : ""
      }`,
    }),
  sendEmail: (_id, status, transactionId, isReferralBonus, transactionDate) =>
    request({
      url: `/payments/reseller/${_id}/${status}${
        transactionId ? `/${transactionId}` : ""
      }/${transactionDate}?shouldSendMail=true${
        isReferralBonus ? `&referralBonus=true` : ""
      }`,
    }),
  supplierPaymentDetail: (_id, status, transactionDate) =>
    request({
      url: `/payments/supplier/${_id}/${status}${
        transactionDate ? `/${transactionDate}` : ""
      }`,
    }),

  allowedReferralReseller: (_id) =>
    request({ url: "/allowed-referral-resellers/" + _id }),

  // Get renterPayments
  renterPayments: ({ params }) => {
    return request({ url: "/admin/get-payments-list", params });
  },

  renterTenants: ({ params }) => {
    return request({ url: `/admin/tenants`, params });
  },
  renterProperties: ({ params }) => {
    return request({ url: `/admin/properties`, params });
  },
  renterLeases: ({ params }) => {
    return request({ url: `/admin/leases`, params });
  },
  renterInvoices: ({ params }) => {
    return request({ url: `/admin/invoice-list`, params });
  },
 
  getInvoiceDetails: (params) => {
    return request({
      url: `/admin/invoice/${params?.id}/payments`,
    });
  },
  getInvoicePaymentDetails: (params) => {
    return request({
      url: `/admin/invoice/${params?.id}/pay-details`,
    });
  },
  banner: ({ params }) => {
    return request({ url: "/admin/banners", params });
  },
};

// Renter Payment Update

export const updateRenterPayment = (payload) => {
  return request({
    method: "patch",
    url: "/admin/payment-update",
    data: payload,
  });
};

export const addPropertyAPI = (payload) => {
  return request({ method: "post", url: "/admin/add-property", data: payload });
};

// export const updateProperty = (payload) => {
//   return request({ method: 'patch', url: '/admin/payment-update', data: payload })
// }

export const getPropertyAdminProfile = async () =>{
  return request({method: "get", url: '/admin/property-admin/profile'})
}

export const getPropertyAdminByName = async (name) =>{
  return request({method: "get", url: `/admin/property-admin/by-name/${name}`})
}

export const updatePropertyAdminInfo = async (id, data) => {
  return request({
    method: "put",
    url: `/admin/property-admin/update-profile/${id}`,
    data,
  });
};

export const getPropertyAdminDetails = async (id) => {
  return request({
    method: "get",
    url: `/admin/property-admin/${id}`
  })
}

export const sendPaymentOTP = async (data) => {
  return request({
    method: "post",
    url: `/user/property-payment/send-otp`,
    data
  })
}

export const verifyPaymentOTP = async (data) => {
  return request({
    method: "post",
    url: `/user/property-payment/verify-otp`,
    data
  })
}

export const sendInvoiceOTP = async (id) => {
  return request({
    method: "post",
    url: `/user/invoice/${id}/send-otp`,
  })
}
export const verifyInvoiceOTP = async (id, data) => {
  return request({
    method: "post",
    url: `/user/invoice/${id}/verify-otp`,
    data,
  })
}

export const getPropertyAdminInfo = async (id) => {
  return request({
    method: "get",
    url: `/user/property-admin/${id}`,
  })
}

export const getUserPropertyInvoice = async (data) => {
  return request({
    method: "get",
    url: `/user/property-invoice`,
    params: data
  })
}

export const createPropertyLease = async (id, data) => {
  return request({
    method: "post",
    url: `/user/create-lease/${id}`,
    data: { data },
  })
}

export const _getLeasedDetail = async (params) => {
  return request({
    method: "get",
    url: `/admin/property/leased-unit/${params.propertyId}/${params.id}`,
  })
}

export const fetchPropertyByCode = async (humanFriendlyId) => {
  return request({
    method: "get",
    url: `/admin/propertyByCode/${humanFriendlyId}`,
  })
}

export const getAllTenantLeases = async (tenantId, propertyId) => {
  return request({
    method: "get",
    url: `/admin/tenants/leased-unit/${tenantId}/${propertyId}`,
  })
}

export const savePaymentIntent = async (invoiceId, paymentIntentId) => {
  return request({
    method: "post",
    url: `/invoice/${invoiceId}`,
    data: {paymentIntentId}
  })
}

export const getAdminUserPaymentSources = async (adminUserId) => {
  return request({
    method: "get",
    url: `/admin/leantech/payment-source/${adminUserId}`,
  })
}

// export const getAll

