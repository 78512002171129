import {
  Box,
  Button,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
  Link,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { getSettings, getUserPropertyInvoice, getPropertyAdminDetails, getUserPaymentSourcesByUserId, disconnectUserLeanBankAccount, sendInvoiceOTP, verifyInvoiceOTP } from "../../services/api";
import InvoiceTable from "../Table/InvoiceTable";
import {
  amountToCommadString,
  calculateInvoiceDue,
  encryptData,
  formatCurrency,
  getPaymentUrl,
  getProcessingFee,
  maskEmail,
  roundValue,
  stringifyPayfortObject,
} from "../../utils";
import { PROCESSING_FEE_TYPES } from "../../constants";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import LeanPaymentGateway from "./LeanPaymentGateway";
import { getPropertyAdminLeanBankDetails } from "../../services/api";
import BankConnectionModal from "./BankConnectionModal";

import bankTransferIcon from "../../assets/bank-transfer-icon.svg";
import { toast } from "react-toastify";

export default function TenantPayment({
  propertyDetails,
  tenantDetails,
  plot,
  leaseApprovalStatus,
  invoice,
  propertyAdminDetails,
  navigateFrom,
  onStepChange,
  setCurrentCompanyPath,
  paymentStep = 1,
  
}) {
  const [invoices, setInvoices] = React.useState([]);
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [settings, setSettings] = React.useState({});
  const [processingFee, setProcessingFee] = React.useState(0);
  const [allowTabby, enableTabbyPayment] = React.useState(true);
  const [leaseApproved, setLeaseApproved] = React.useState(false);
  const [plotNumber, setPlotNumber] = React.useState("");
  const [amountDue, setAmountDue] = React.useState(0);
  const [totalToPay, setTotalToPay] = React.useState(0);
  const [showBankTransfer, setShowBankTransfer] = React.useState(false);
  const tabbyFee = React.useRef(0);
  const [paymentMethodType, setPaymentMethodType] = useState("");
  const [leanModal, setLeanModal] = React.useState(false);
  const [leanEnable, setLeanEnable] = React.useState(true);
  const [propertyAdminShortName, setPropertyAdminShortName] = React.useState("");
  const [currentStep, setCurrentStep] = React.useState(paymentStep);
  const [showBankInfoModal, setShowBankInfoModal] = useState(false);
  const [connectedLeanUser, setConnectedLeanUser] = useState(false);
  const [isProcessRunning, setIsProcessRunning] = useState(false);
  const [fetchLeanStatus, setFetchLeanStatus] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [resendTimer, setResendTimer] = useState(0);
  const [leanPaymentSources, setLeanPaymentSources] = useState([]);
  const [coolOffCountdown, setCoolOffCountdown] = useState('');
  const [leanPaymentSourceTries, setLeanPaymentSourceTries] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  React.useEffect(() => {
    if (amountDue > 0 && paymentMethodType) {
      handlePaymentAmount(amountDue);
    }
  }, [amountDue, settings, paymentMethodType]);

  React.useEffect(()=>{
    if(propertyDetails.propertyAdminId){
      getPropertyAdminLeanBankDetails(propertyDetails.propertyAdminId)
    .then((response)=>{if(response.data?.paymentDestinationId){setLeanEnable(true)}else{setLeanEnable(false)}})
    .catch((error)=>{console.log("error", error)})
    }
  },[])

  React.useEffect(()=>{
    if(propertyDetails.propertyAdminId){
      getPropertyAdminDetails(propertyDetails.propertyAdminId)
      .then((response)=>{setPropertyAdminShortName(response.propertyAdminDetails.shortName)})
      .catch((error)=>{console.log("error", error)})
    }
    if (tenantDetails?.id && propertyAdminDetails?.lean_enabled && leanEnable) {
      getUserPaymentSourcesByUserId({userId: tenantDetails?.id})
      .then((response)=>{
        const paymentSources = response.data;
        if (paymentSources.length > 0) {
          setConnectedLeanUser(true);
          setLeanPaymentSources(paymentSources);
        } else {
          setLeanPaymentSources([]);
        }
      })
      .catch((error)=>{console.log("error", error)})
    }
  },[])

  React.useEffect(() => {
    if (fetchLeanStatus) {
      setPaymentMethodType("");
      getUserPaymentSourcesByUserId({userId: tenantDetails?.id})
      .then((response)=>{
        const paymentSources = response.data;
        if (paymentSources.length > 0) {
          setConnectedLeanUser(true);
          setLeanPaymentSources(paymentSources);
        } else {
          setConnectedLeanUser(false);
          setLeanPaymentSources([]);
        }
        setFetchLeanStatus(false);
      })
      .catch((error)=>{console.log("error", error)});
    }
  }, [fetchLeanStatus]);

  const setInvoiceByIdDetails = async () => {
    setLoading(true);
    try {
      let { data: settings } = await getSettings();
      setSettings(settings);
      setPlotNumber(plot);
      setLeaseApproved(leaseApprovalStatus);
      setInvoices(invoice);
    } catch (error) {
      console.log("Error fetching invoices:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      let { data: settings } = await getSettings();
      setSettings(settings);
      const response = await getUserPropertyInvoice({
        userId: tenantDetails?.id,
        propertyId: propertyDetails?.id,
      });
      setInvoices(response.data.invoices);
      setLeaseApproved(response.data.lease_approved);
      setPlotNumber(response.data.apartmentNumber.plotNumber);
    } catch (error) {
      console.log("Error fetching invoices:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (id) => {
    if (!invoices[id]) return;
    const selectedAmount = calculateInvoiceDue(
      invoices[id]?.amount,
      invoices[id]?.payments
    );
    setAmountDue(selectedAmount);
    setSelectedInvoice(id);
  };

  const makeInvoicePayment = () => {
    if (selectedInvoice === null || paymentMethodType === "") {
      return;
    }
    if (paymentMethodType === "lean") {
      // Check if user already have bank connection setup for lean
      if (connectedLeanUser) {
        setLeanModal(true);
      } else {
        setShowBankTransfer(true);
      }
    } else {
      const addedDetails = {
        userId: tenantDetails?.id,
        invoiceId: invoices[selectedInvoice]?.id,
        processingFee: processingFee,
        originalAmount: amountDue,
        vatApplied: 0,
        paymentMode: paymentMethodType,
      };

      const paymentObject = {
        invoiceId: addedDetails.invoiceId,
        amount: totalToPay,
        extra: stringifyPayfortObject(addedDetails),
      };

      const data = encryptData(JSON.stringify(paymentObject));
      const payURL = getPaymentUrl({ data });
      window.location.href = payURL;
    }
  };

  const handlePaymentAmount = (amount, setting = settings) => {
    if (paymentMethodType === "") return;
    let mainAmount = amount.toString().replaceAll(",", "");
    if (mainAmount <= 5000) {
      enableTabbyPayment(true);
    } else {
      enableTabbyPayment(false);
    }
    let pay = amount;
    if (isNaN(pay)) {
      pay = pay.replaceAll(",", "");
    }
    let total = Number(pay);
    let fee = 0;
    let vatPercent = 0;
    if (paymentMethodType === "tabby" && mainAmount < 5000) {
      fee = pay * 6 * 0.01;
      tabbyFee.current = fee;
    }else if(paymentMethodType === "lean"){
      fee = 0;
    } 
    else if (setting?.processingFeesType === PROCESSING_FEE_TYPES[0]) {
      fee = setting?.processingFees;
    } else {
      fee = pay * setting?.processingFees * 0.01;
    }
    fee = roundValue(fee);
    if (vatPercent === 0) {
      total = Math.ceil(total + fee);
    } else {
      total = Math.ceil(total + fee + roundValue(fee * vatPercent * 0.01));
    }
    setProcessingFee(fee);
    setTotalToPay(total);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethodType(e.target.value);
    handlePaymentAmount(amountDue);
  };
  const params = useParams();
  React.useEffect(() => {
    if (!params.id) fetchInvoices();
    else setInvoiceByIdDetails();
  }, [params.id]);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleProceedToPayment = () => {
    setCurrentStep(3);
    setCurrentCompanyPath(2);
    onStepChange?.(3);
  };

  const handleBackToDetails = () => {
    setCurrentStep(2);
    setCurrentCompanyPath(1);
    setPaymentMethodType("");
    onStepChange?.(2);
  };

  const disconnectLeanBank = async () => {
    toast.info("Disconnecting your bank account");
    setIsProcessRunning(true);
    const response = await disconnectUserLeanBankAccount({userId: tenantDetails?.id});
    if (['BANK_DISCONNECTED', 'BANK_ALREADY_DISCONNECTED'].includes(response.message)) {
      setConnectedLeanUser(false);
      toast.success("Bank connection removed successfully");
    } else {
      toast("Failed to disconnect bank account")
    }
    setFetchLeanStatus(false);
    setLeanPaymentSources([]);
    setCoolOffCountdown('');
    setLeanPaymentSourceTries(false);

    setIsProcessRunning(false);
  };

  const handleBankInfoClick = (e) => {
    e.preventDefault();
    setShowBankInfoModal(true);
  };

  const calculateTimeLeft = (expiryDate) => {
    const now = new Date().getTime();
    const expiry = new Date(expiryDate).getTime() + 240000; // Adding 4 minutes to the expiry time
    const difference = expiry - now;

    if (difference <= 0) {
      return '00:00:00';
    }

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);


    let timeString = '';
    
    if (hours > 0) {
      timeString += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
      // if (minutes > 0) {
      //   timeString += ` ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
      // }
    } else if (minutes > 0) {
      timeString += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
      // if (seconds > 0) {
      //   timeString += ` ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
      // }
    } else {
      timeString = `${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
    }

    return timeString;
    // return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  React.useEffect(() => {
    let timer;
    if (leanPaymentSources[0]?.status === "AWAITING_BENEFICIARY_COOL_OFF" && leanPaymentSources[0]?.coolOffExpiry) {
      let timeout = 1000;
      if (leanPaymentSourceTries) {
        timeout = 30000;
      }
      timer = setInterval(() => {
        const timeLeft = calculateTimeLeft(leanPaymentSources[0].coolOffExpiry);
        setCoolOffCountdown(timeLeft);
        
        if (timeLeft === '00:00:00') {
          clearInterval(timer);
          setFetchLeanStatus(true);
          setLeanPaymentSourceTries(true);
        }
      }, timeout);
    } else {
      setLeanPaymentSourceTries(false);
    }
    return () => clearInterval(timer);
  }, [leanPaymentSources]);

  const renderPaymentMethodStep = () => {
    if (showBankTransfer) {
      return (
        <>
          <Box
            mx="auto"
            width="100%"
            bgcolor="#FFF"
            borderRadius="10px"
          >
            <Typography variant="h5"  style={{ fontWeight: "bold", marginBottom: "24px", textAlign: "center", color: "#092B4F" }}>
              Easy Bank Transfers
            </Typography>

            <Box
              width="100%"
              mx="auto"
              borderBottom="1px solid #E0E0E0"
              mb={3}
            />

            <Box display="flex" justifyContent="center" mb={4}>
              <img 
                src={bankTransferIcon} 
                alt="Bank Transfer" 
                style={{ width: '120px', height: '120px' }}
              />
            </Box>

            <Typography 
              variant="body1" 
              style={{ 
                textAlign: "center", 
                marginBottom: "32px",
                fontSize: "16px",
                maxWidth: "400px",
                margin: "0 auto 32px",
                color: "#092B4F"
              }}
            >
              You can now securely connect your bank account and make instant rent payments right from the Renters app.
            </Typography>

            <Box display="flex" justifyContent="center" mb={3}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#01D4A1",
                  color: "#FFF",
                  fontWeight: "500",
                  borderRadius: "48px",
                  padding: "12px 32px",
                  fontSize: "16px",
                  textTransform: "none",
                  width: "100%",
                  maxWidth: "400px"
                }}
                onClick={() => setLeanModal(true)}
              >
                Connect Your Bank
              </Button>
            </Box>

            <Box display="flex" justifyContent="center">
              <Link
                component="button"
                variant="body2"
                onClick={handleBankInfoClick}
                style={{ 
                  color: "#092B4F",
                  textDecoration: "underline",
                  fontSize: "14px",
                  fontWeight: "500"
                }}
              >
                How we connect securely with your bank
              </Link>
            </Box>
          </Box>
          <BankConnectionModal
            open={showBankInfoModal}
            onClose={() => setShowBankInfoModal(false)}
            onConnectBank={() => setLeanModal(true)}
          />
        </>
      );
    }

    return (
      <Box
        mx="auto"
        width="100%"
        bgcolor="#FFF"
      > 
        <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "16px", color: "#092B4F" }}>
          Payment Method
        </Typography>

        <Box width="100%" mx="auto" borderBottom="1px solid #E0E0E0" mb={3} />

        <Box mb={4}>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            gap="14px"
            gridColumnGap={isMobile ? "0" : "14px"}
            gridRowGap={isMobile ? "14px" : "0"}
            justifyContent="space-between"
          >
            <Box width={isMobile ? "100%" : "48%"}>
              <TextField
                disabled
                label="Total Due"
                variant="outlined"
                value={selectedInvoice === null ? "" : amountToCommadString(amountDue)}
                fullWidth
                InputProps={{
                  style: { color: "#092B4F" },
                }}
              />
            </Box>
            <Box width={isMobile ? "100%" : "48%"}>
              <TextField
                disabled
                label="Amount to Pay"
                variant="outlined"
                value={selectedInvoice === null ? "" : amountToCommadString(totalToPay)}
                fullWidth
                InputProps={{
                  style: { color: "#092B4F" },
                }}
              />
              <Typography variant="body2" style={{ marginTop: "6px", color: "#092B4F" }}>
                Processing Fee:{" "}
                {paymentMethodType === ""
                  ? ""
                  : paymentMethodType === "tabby"
                  ? `${formatCurrency(Math.ceil(tabbyFee.current))}`
                  : paymentMethodType === "lean"
                  ? "0"
                  : getProcessingFee(settings)}
              </Typography>
            </Box>
          </Box>
        </Box>
        {propertyAdminDetails?.lean_enabled && leanEnable && (
          <Box mb={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold", color: "#6B7280", textTransform: "uppercase", fontSize: "12px", marginBottom: "8px" }}>
              RECOMMENDED
            </Typography>
            
            <Box
              border="1px solid #E5E7EB"
              borderRadius="12px"
              p={2}
              mb={2}
              bgcolor={paymentMethodType === "lean" ? "#F1FBF7" : "transparent"}
              // onClick={() => handlePaymentMethodChange({ target: { value: "lean" } })}
              onClick={() => (fetchLeanStatus || leanPaymentSourceTries || leanPaymentSources[0]?.status === "AWAITING_BENEFICIARY_COOL_OFF") ? null : handlePaymentMethodChange({ target: { value: "lean" } })}
              style={{ cursor: !(fetchLeanStatus || leanPaymentSourceTries || leanPaymentSources[0]?.status === "AWAITING_BENEFICIARY_COOL_OFF") ? "pointer" : "not-allowed", opacity: !(fetchLeanStatus || leanPaymentSourceTries || leanPaymentSources[0]?.status === "AWAITING_BENEFICIARY_COOL_OFF") ? 1 : 0.75 }}
            >
              <Box display="flex" alignItems="flex-start">
                <Box display="flex" alignItems="flex-start" style={{flexDirection: "column"}}>
                <Box display="flex" alignItems="center" style={{justifyContent: "space-between", width: "100%"}}>
                  <Box display="flex"  alignItems="center">
                  <Radio
                    checked={paymentMethodType === "lean"}
                    value="lean"
                    disabled={(fetchLeanStatus || leanPaymentSourceTries || leanPaymentSources[0]?.status === "AWAITING_BENEFICIARY_COOL_OFF")}
                    onChange={(e) => handlePaymentMethodChange(e)}
                    style={{ color: "#22C55E", paddingLeft: 0 }}
                  />
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#1F2937" }}>
                    Instant Payments
                  </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1} style={{ justifyContent: "space-between"}}>
                    <Box style={{backgroundColor: "#01D4A1"}} px={1} py={0.5} borderRadius="6px">
                      <Typography variant="caption" style={{ color: "#202020", fontWeight: "bold" }}>
                        FASTEST
                      </Typography>
                    </Box>
                  </Box>
                    </Box>
                    <Box>
                    <Typography variant="body2">
                      Easily connect your bank account and initiate quick payments in just a few clicks
                    </Typography>
                    {/* Connection Status */}
                      <Box display="flex" flexDirection="column"  mt={2}>
                        {connectedLeanUser && (
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                              <Box display="flex">
                                <Typography variant="body2" style={{ color: "#00A47D", fontWeight: "bold", fontSize: "12px" }}>
                                  Connected to {leanPaymentSources[0]?.bankIdentifier}
                                </Typography>
                                {/* <Typography variant="body2" style={{ color: "#000000", fontSize: "12px" }}>
                                {leanPaymentSources[0]?.bankIdentifier}
                                </Typography> */}
                              </Box>
                              <Link
                                component="button"
                                variant="body2"
                                onClick={() => disconnectLeanBank()}
                                style={{
                                  color: "#6B7280",
                                  textDecoration: "underline",
                                  fontSize: "10px",
                                  cursor: "pointer",
                                  fontWeight: "600"
                                }}
                              >
                                Disconnect
                              </Link>
                            </Box>
                        )}
                        <Box display="flex" alignItems="center">
                            {(leanPaymentSourceTries || fetchLeanStatus) ? (<Box display="flex">
                                {/* <Typography variant="body2" style={{ color: "#00A47D", fontWeight: "bold", fontSize: "12px" }}>
                                  Status: 
                                </Typography> */}
                                <Typography variant="body2" style={{ color: "#6B7280", fontSize: "12px" }}>
                                  Checking Account status...
                                </Typography>
                              </Box>) : leanPaymentSources[0]?.status === "AWAITING_BENEFICIARY_COOL_OFF" 
                              ? (<Box display="flex">
                                {/* <Typography variant="body2" style={{ color: "#00A47D", fontWeight: "bold", fontSize: "12px" }}>
                                  Status: 
                                </Typography> */}
                                <Typography variant="body2" style={{ color: "#000000", fontSize: "12px" }}>
                                  Your account will be ready for payments in less than {coolOffCountdown}
                                </Typography>
                              </Box>)
                              : ''}
                          
                        </Box>
                      </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Box>
        {propertyAdminDetails?.lean_enabled && leanEnable ? (
          <Typography variant="subtitle2" style={{ fontWeight: "bold", color: "#6B7280", textTransform: "uppercase", fontSize: "12px", marginBottom: "8px" }}>
            OTHER METHODS
          </Typography>
        ) : (
          <Typography variant="subtitle2" style={{ fontWeight: "bold", color: "#6B7280", textTransform: "uppercase", fontSize: "12px", marginBottom: "8px" }}>
            Select Payment Method
          </Typography>
        )}
          
          {propertyAdminDetails?.credit_card_enabled && (
            <Box
              border="1px solid #E5E7EB"
              borderRadius="12px"
              p={2}
              mb={2}
              bgcolor={paymentMethodType === "card" ? "#F8FAFC" : "transparent"}
              onClick={() => handlePaymentMethodChange({ target: { value: "card" } })}
              style={{ cursor: "pointer" }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Radio
                    checked={paymentMethodType === "card"}
                    value="card"
                    onChange={(e) => handlePaymentMethodChange(e)}
                    style={{ color: "#000", paddingLeft: 0 }}
                  />
                  <Box>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#1F2937" }}>
                      Card Payment
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {propertyAdminDetails?.tabby_enabled && (
            <Box
              border="1px solid #E5E7EB"
              borderRadius="12px"
              p={2}
              mb={2}
              bgcolor={paymentMethodType === "tabby" ? "#F8FAFC" : "transparent"}
              onClick={() => !allowTabby ? null : handlePaymentMethodChange({ target: { value: "tabby" } })}
              style={{ cursor: allowTabby ? "pointer" : "not-allowed", opacity: allowTabby ? 1 : 0.5 }}
            >
              <Box display="flex" alignItems="center">
                <Radio
                  checked={paymentMethodType === "tabby"}
                  value="tabby"
                  onChange={(e) => handlePaymentMethodChange(e)}
                  style={{ color: "#000", paddingLeft: 0 }}
                />
                <Box>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                    Tabby
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box mt={4} display="flex" justifyContent="space-between" width="100%">
          <Button
            variant="outlined"
            style={{
              backgroundColor: "transparent",
              color: "#01D4A1",
              fontWeight: "bold",
              borderRadius: "48px",
              padding: "8px 30px",
              borderColor: "#01D4A1",
            }}
            onClick={handleBackToDetails}
          >
            Back
          </Button>
          <Button
            variant="contained"
            disabled={isProcessRunning || paymentMethodType === ""}
            style={{
              backgroundColor: "#01D4A1",
              color: "#FFF",
              fontWeight: "bold",
              borderRadius: "48px",
              padding: "8px 30px",
              opacity: paymentMethodType === "" ? 0.5 : 1,
            }}
            onClick={makeInvoicePayment}
          >
            Proceed
          </Button>
        </Box>
      </Box>
    );
  };

  const renderPropertyDetailsStep = () => {
    return (
      <>
        <Typography variant="h5" style={{ fontWeight: "bold", marginBottom: "24px" }}>
          Property Details
        </Typography>

        <Box
          width="100%"
          mx="auto"
          borderBottom="1px solid #E0E0E0"
          mb={3}
        />

        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          gap="10px"
          mb={3}
          justifyContent="space-between"
          gridRowGap={isMobile ? "10px" : "0"}
        >
          <Box
            border="1px solid #01D4A1"
            borderRadius={5}
            width={isMobile ? "100%" : "48%"}
            px={3}
            py={1}
          >
            <Typography variant="body1" fontWeight="bold" style={{color: "#092B4F"}}>
              Lessor:
            </Typography>
            <Typography variant="body2" style={{color: "#092B4F"}}>
              {propertyDetails?.propertyLessor?.lessorName}
            </Typography>
            <Typography variant="body2" style={{color: "#092B4F"}}>
              {[propertyDetails?.propertyName, propertyDetails?.buildingName]
                .filter(Boolean)
                .join(", ")}
            </Typography>
            <Typography variant="body2" style={{color: "#092B4F"}}>
              {[propertyDetails?.landArea, propertyDetails?.city]
                .filter(Boolean)
                .join(", ")}
            </Typography>
          </Box>

          <Box
            border="1px solid #01D4A1"
            borderRadius={5}
            width={isMobile ? "100%" : "48%"}
            px={3}
            py={1}
          >
            <Typography variant="body1" style={{color: "#092B4F"}} fontWeight="bold">
              Tenant:
            </Typography>
            <Typography variant="body2" style={{color: "#092B4F"}}>
              {tenantDetails?.name}
            </Typography>
            <Typography variant="body2" style={{color: "#092B4F"}}>
              {tenantDetails?.email_id}
            </Typography>
            <Typography variant="body2" style={{color: "#092B4F"}}>
              {plotNumber}
            </Typography>
          </Box>
        </Box>

        {leaseApproved ? (
          invoices.length === 0 ? (
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: "20px", color: "#888" }}
            >
              There are no invoices due for payment. Please check back later.
            </Typography>
          ) : (
            <>
              <InvoiceTable
                handleSelect={handleSelect}
                invoices={invoices}
                selectedInvoice={selectedInvoice}
              />

              <Box mt={3} style={{textAlign: "center"}}>
                {invoices.every((invoice) => roundValue(
                    calculateInvoiceDue(invoice?.amount, invoice?.payments)
                  ) <= 0) ? (
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ marginTop: 2, color: "#888" }}
                  >
                    No Payment is due
                  </Typography>
                ) :
                (propertyAdminDetails?.lean_enabled ||
                  propertyAdminDetails?.tabby_enabled ||
                  propertyAdminDetails?.credit_card_enabled) ? (
                  <Button
                    variant="contained"
                    disabled={selectedInvoice === null}
                    style={{
                      margin: "0 auto",
                      backgroundColor: "#01D4A1",
                      color: "#FFF",
                      fontWeight: "bold",
                      borderRadius: "48px",
                      padding: "8px 30px",
                      opacity: selectedInvoice === null ? 0.5 : 1,
                    }}
                    onClick={handleProceedToPayment}
                  >
                    Proceed
                  </Button>
                ) : (
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ marginTop: 2, color: "#888" }}
                  >
                    Payment methods are disabled. Please contact your property
                    manager.
                  </Typography>
                )}
              </Box>
            </>
          )
        ) : (
          <Typography
            variant="h6"
            align="center"
            style={{ marginTop: 2, color: "#888" }}
          >
            Your lease is under approval. Please contact your property
            manager.
          </Typography>
        )}
      </>
    );
  };

  const renderEmailVerificationStep = () => {
    return (
      <Box
        mx="auto"
        my={4}
        p={isMobile ? 2 : 4}
        width={isMobile ? "90%" : "600px"}
        bgcolor="#FFF"
        borderRadius="10px"
        border="1px solid #01D4A1"
      >
        <Typography variant="h5" style={{ fontWeight: "bold", marginBottom: "24px", color: "#092B4F" }}>
          Verify Email
        </Typography>

        <Box
          width="100%"
          mx="auto"
          borderBottom="1px solid #E0E0E0"
          mb={3}
        />

        <Box mb={4}>
          <Typography variant="body1" style={{ marginBottom: "16px", color: "#092B4F" }}>
            Please verify your email address to proceed with the payment
          </Typography>

          <TextField
            disabled
            fullWidth
            label="Email Address"
            variant="outlined"
            value={maskEmail(tenantDetails?.email_id)}
            style={{ marginBottom: "32px" }}
          />

          <Box width="100%" display="flex">
            {!otpSent ? (
              <Button
                variant="contained"
                disabled={isProcessRunning || !leaseApproved}
                style={{
                  backgroundColor: !leaseApproved ? "#888" : "#01D4A1",
                  cursor: !leaseApproved ? "not-allowed" : "pointer",
                  color: "#FFF",
                  fontWeight: "bold",
                  borderRadius: "48px",
                  padding: "8px 30px",
                  float: "right",
                }}
                onClick={handleSendOtp}
              >
                Send OTP
              </Button>
            ) : (
              <Box>
                <TextField
                  fullWidth
                  label="Enter OTP"
                  variant="outlined"
                  type={showOtp ? "text" : "password"}
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                    setOtpError('');
                  }}
                  error={!!otpError}
                  helperText={otpError}
                  style={{ marginBottom: "16px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowOtp(!showOtp)}
                          edge="end"
                        >
                          {showOtp ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Button
                    variant="text"
                    disabled={isProcessRunning || resendTimer > 0}
                    style={{
                      color: "#01D4A1",
                      textTransform: "none",
                    }}
                    onClick={handleSendOtp}
                  >
                    {resendTimer > 0 ? `Resend OTP in ${resendTimer}s` : 'Resend OTP'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isProcessRunning || !otp}
                    style={{
                      backgroundColor: "#01D4A1",
                      color: "#FFF",
                      fontWeight: "bold",
                      borderRadius: "48px",
                      padding: "8px 30px",
                    }}
                    onClick={handleVerifyOtp}
                  >
                    Verify
                  </Button>
                </Box>
              </Box>
            )}
          </Box>

          {!leaseApproved && (
            <Box width="100%" display="flex" marginTop={4}>
              <Typography variant="h6" style={{ marginTop: 2, color: "#888" }}>
                Your lease is under approval. Please contact your property
                manager.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  // Function to send OTP
  const handleSendOtp = async () => {
    setIsProcessRunning(true);
    try {
      // Send Invoice OTP
      // pick firsy invoice from invoice array
      if (invoice.length > 0) {
        await sendInvoiceOTP(invoice[0]?.id);
        setOtpSent(true);
        setOtpError('');
        setResendTimer(30); // Start 30 second timer
      } else {
        setOtpError('No invoice found to send OTP');
      }
    } catch (error) {
      setOtpError('Failed to send OTP. Please try again.');
    }
    setIsProcessRunning(false);
  };

  // Function to verify OTP
  const handleVerifyOtp = async () => {
    setIsProcessRunning(true);
    try {
      // TODO: Replace with actual API call
      if (invoice.length > 0) { 
        const response = await verifyInvoiceOTP(invoice[0]?.id, {otp});
        // if verified, move to next step else show error
        if(response.data?.status === 'OTP_VERIFIED') {
          setCurrentStep(2);
          onStepChange?.(2);
        } else {
          setOtpError('Invalid OTP. Please try again.');
        }
      } else {
        setOtpError('No invoice found to verify OTP');
      }
    }
    catch (error) {
      setOtpError('Failed to verify OTP. Please try again.');
    }
    setIsProcessRunning(false);
  };

  React.useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [resendTimer]);

  if (loading) {
    return <Typography variant="h5">Loading...</Typography>;
  }

  return (
    <>
      {leanModal ? (
        <Box bgcolor='#fafafa'>
          <LeanPaymentGateway
            amount={totalToPay}
            propertyShortCode={propertyAdminShortName}
            currency="AED"
            invoiceId={invoices[selectedInvoice]?.id}
            navigateFrom={navigateFrom}
            tenantDetails={tenantDetails}
            propertyAdminDetails={propertyAdminDetails}
            onStepChange={onStepChange}
            setLeanModal={setLeanModal}
            setShowBankTransfer={setShowBankTransfer}
            setFetchLeanStatus={setFetchLeanStatus}
          />
        </Box>
      ) : (
        <>
          {currentStep === 1 && renderEmailVerificationStep()}
          {currentStep === 2 && (
            <Box
              mx="auto"
              my={4}
              p={isMobile ? 2 : 4}
              width={isMobile ? "90%" : "600px"}
              bgcolor="#FFF"
              borderRadius="10px"
              border="1px solid #01D4A1"
            >
              {renderPropertyDetailsStep()}
            </Box>
          )}
          {currentStep === 3 && (
            <Box
              mx="auto"
              my={4}
              p={isMobile ? 2 : 4}
              width={isMobile ? "90%" : "600px"}
              bgcolor="#FFF"
              borderRadius="10px"
              border="1px solid #01D4A1"
            >
              {renderPaymentMethodStep()}
            </Box>
          )}
        </>
      )}
    </>
  );
}
